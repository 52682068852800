<template>
  <div class="tariff__page">
    <Breadcrumb
      :breadcrumbs="breadcrumbs"
      mainLabelName="Тарифный план"
    ></Breadcrumb>
    <a-tabs>
      <a-tab-pane key="1" tab="Тариф">
        <TabViewContainer>
          <TariffPlan
            @changeTariffView="changeTariffView"
            :isHistoryPay="isHistoryPay"
          ></TariffPlan>
        </TabViewContainer>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import TariffPlan from '@/components/MasterComponents/Tariff/TariffPlan.vue'
import TabViewContainer from '@/components/common/TabViewContainer.vue'
import Breadcrumb from '@/components/common/Breadcrumb.vue'

export default {
  components: {
    TabViewContainer,
    Breadcrumb,
    TariffPlan
  },

  data () {
    return {
      isHistoryPay: false,
      breadcrumbs: [
        {
          id: 1,
          name: 'Главная',
          link: '/main'
        },
        {
          id: 2,
          name: 'Тариф',
          link: '/tariff'
        }
      ]
    }
  },
  methods: {
    changeTariffView () {
      this.isHistoryPay = !this.isHistoryPay
    }
  }
}
</script>

<style>
.tariff__page {
  background-color: white;
  width: 100%;
  height: 100%;
}

.profile-label {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
}

.profile-master-personal-tab-container {
  background-color: #f6f6f6;
  padding-right: 15%;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-left: 20px;
  padding-top: 17px;
  padding-bottom: 15%;
}
</style>
